@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }
  
  body {
    @apply bg-neutral text-neutral-dark;
  }
  
  h1 {
    @apply text-4xl font-bold mb-4;
  }
  h2 {
    @apply text-3xl font-bold mb-3;
  }
  h3 {
    @apply text-2xl font-bold mb-2;
  }
}

@layer components {
  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full;
  }
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-colors duration-200;
  }
  .btn-primary {
    @apply bg-primary text-white hover:bg-primary-dark;
  }
  .btn-secondary {
    @apply bg-secondary text-white hover:bg-secondary-dark;
  }
  
  /* Slider özelleştirmeleri */
  .slick-slider {
    @apply w-full overflow-hidden;
  }
  
  .slick-slide > div {
    @apply w-full;
  }
}

@layer utilities {
  .animation-delay-300 {
    animation-delay: 300ms;
  }
}
